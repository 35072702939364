import { template as template_a94c18e6fb0f4c0fbe97df3f49398b60 } from "@ember/template-compiler";
const FKText = template_a94c18e6fb0f4c0fbe97df3f49398b60(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
