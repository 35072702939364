import { template as template_5e57cba669c34b9b9f1fc3379f2709d8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5e57cba669c34b9b9f1fc3379f2709d8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
